import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import * as css from "./productList.module.css"
import { FormattedMessage } from "react-intl"

const defaultLangKey = "en"

const getFirstImage = node => {
  const firstImage = node.images && node.images[0]
  return firstImage
}

export default props => {
  const { products, langKey = defaultLangKey } = props

  return (
    <div className={css.productList}>
      {products &&
        products.map(product => {
          if (!product) {
            return <FormattedMessage id="noProductInformation" />
          }
          const { id, title, description, prices, slug } = product
          // const fixed = getFixedProps(getFirstImage(product))
          const image = getFirstImage(product)
          const fromPrice = prices && prices[0]
          if (!slug[langKey]) {
            return false
          }
          return (
            <div key={id} className={css.product}>
              <GatsbyImage
                image={image.asset.gatsbyImageData}
                className={css.image}
                alt={title[langKey] || ""}
              />
              <h2>{title[langKey] || title[defaultLangKey]}</h2>
              <p>{description[langKey]}</p>
              <Link
                to={`/product/${slug[langKey].current}`}
                className={css.button}
              >
                from {fromPrice.price[langKey] || fromPrice.price.en} NOK
              </Link>
            </div>
          )
        })}
    </div>
  )
}
